
import { ref } from 'vue';
import {deleteUser, getUserList, getRoles, addUser, updateUser, exportUser} from '@/api/user';
import { useStore } from 'vuex';
import {ElMessage, ElMessageBox} from 'element-plus';
import {download} from "@/api/download";
import fileDownload from 'js-file-download';

export default {
    setup(){
        const userFormVisible = ref(false);

        const validate: any = ref(null);

        const userEditFormVisible = ref(false);

        const loading = ref(false);

        const store = useStore();

        const formSize = ref('large');

        const formRules = {
            username: [
              {
                  required: true,
                  message: '请输入用户名',
                  trigger: 'blur',
              },
              {
                  min: 4,
                  max: 20,
                  message: '用户名长度必须在4-20个字符之间',
                  trigger: 'blur',
              }
            ],
            password: [
            {
                required: true,
                message: '请输入密码',
                trigger: 'blur',
            },
            {
                min: 6,
                max: 18,
                message: '密码长度必须在6-18个字符之间',
                trigger: 'blur',
            },
            ],
            nickname: [
              {
                max: 30,
                message: '昵称不能超过30个字符!',
                trigger: 'blur',
              }
            ],
            phone: [
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                message: "手机号码格式不正确!",
                trigger: "blur"
              }
            ],
            email: [
              {
                max: 30,
                message: "邮箱不能超过30个字符",
                trigger: ["blur", "change"]
              },
              {
                type: "email",
                message: "邮箱格式不正确",
                trigger: ["blur", "change"]
              }
            ],
        };

        const editFormRules = {
          username: [
            {
                required: true,
                message: '请输入用户名',
                trigger: 'blur',
            },
            {
              min: 4,
              max: 20,
              message: '用户名长度必须在4-20个字符之间',
              trigger: 'blur',
            }
          ],
          password: [
            {
                min: 6,
                max: 18,
                message: '密码长度必须在6-18个字符之间',
                trigger: 'blur',
            }
          ],
          nickname: [
            {
              max: 30,
              message: '昵称不能超过30个字符!',
              trigger: 'blur',
            }
          ],
          phone: [
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: "请输入正确的手机号码",
              trigger: "blur"
            }
          ],
          email: [
            {
              max: 30,
              message: "邮箱不能超过30个字符!",
              trigger: ["blur", "change"]
            },
            {
              type: "email",
              message: "邮箱格式不正确",
              trigger: ["blur", "change"]
            }
          ]


        }

        const getLockedDict = (locked) => {
            let label = '';
            let type = '';
            if(locked){
                label = '锁定';
                type = 'warning';
            }else{
                label = '正常';
                type = 'success';
            }
            return {"label": label,"type": type};
        };


        const userLocked = [
            {
                locked: true,
                label: '锁定',
            },
            {
                locked: false,
                label: '正常',
            }
        ];
        //适配移动端
        if(store.state.app.isMobile){
            formSize.value = 'mini';
        }else{
            formSize.value = 'large';
        }

        const table:any = ref({
            page: 1,        // 当前在第几页
            pageSize: 10,   // 一页显示多少
            total: 0,       // 数据总量
            loading: false,  // 加载中
            data: [],       // 表格数据
            isMobile: false,// 表格是否移动端
        });

        const searchParams: any = ref({
            username: '',
            nickname: '', 
            phone: '',
            email: '',
            locked: '',
        });

        const userForm: any = ref({
          id: null,
          username: '',
          password: '',
          nickname: '',
          phone: '',
          email: '',
          roles: [],
          locked: 0
        });

        table.value.isMobile = store.state.app.isMobile;

        const exporting = ref(false);


        //获取用户列表
        const listUsers = () => {
            table.value.loading = true,
            getUserList({
                page: table.value.page,
                pageSize: table.value.pageSize,
            }).then((res: any) => {
                table.value.data = res.data.list;
                table.value.total = res.data.total;
                table.value.loading = false;
            });
        };

        listUsers();

        const roles = ref([]);

        //获取角色列表
        getRoles().then((res: any) => {
            roles.value = res.data;
        });

        const handleSizeChange = (pageSize: number) => {
            table.value.pageSize = pageSize;
            listUsers();
        };

        const handleCurrentChange = (page: number) => {
            table.value.page = page;
            listUsers();
        };

        const searchUsers = () => {
            table.value.loading = true,
            getUserList({
                page: table.value.page,
                pageSize: table.value.pageSize,
                username: searchParams.value.username,
                nickname: searchParams.value.nickname,
                phone: searchParams.value.phone,
                email: searchParams.value.email,
                locked: searchParams.value.locked,

            }).then((res: any) => {
                table.value.data = res.data.list;
                table.value.total = res.data.total;
                table.value.loading = false;
            });
        };

        const handleDelete = (id) => {
            deleteUser(id).then((res: any) => {
                if(!res.errCode) {
                    ElMessage.success("删除成功!");
                    listUsers();
                }else{
                    ElMessage.error(res.detail);
                }
            });
        };

        const handleEditClick = (row) => {
            userForm.value = {};
            const formData = JSON.parse(JSON.stringify(row)); //消除row的响应性
            //获取角色id
            let roleIds = [];
            userForm.value = formData;
            row.roleList.forEach(item => {
                roleIds.push(item.id);
                userForm.value.roles = roleIds;
            });
            userEditFormVisible.value = true;

        };


        const handleEditUser = () => {
            validate.value.validate((valid) => {
                if(valid){
                    loading.value = true;
                    updateUser(userForm.value).then((res: any) => {
                        if(!res.errCode) {
                            ElMessage.success('更新用户成功!');
                            userEditFormVisible.value = false;
                            listUsers();
                        } else {
                            ElMessage.error(res.detail);
                        }      
                        loading.value = false;
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });

        };

        const handleAddUser = () => {
            validate.value.validate((valid) => {
                if(valid) {
                    loading.value = true;
                    addUser(userForm.value).then((res: any) => {
                        if(!res.errCode){
                            ElMessage.success('新建用户成功!');
                            userFormVisible.value = false;
                            listUsers();
                        }else{
                            ElMessage.error(res.detail);
                        }
                        loading.value = false;
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        const handleExport = () => {
          ElMessageBox.confirm('是否导出？', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function() {
            exporting.value = true;
            exportUser().then((res: any) => {
              if(res.data) {
                const path = res.data;
                download(path).then((blob: any) => {
                  fileDownload(blob,new Date().getTime() + "_用户数据.xlsx");
                  exporting.value = false;
                }).catch(() => {
                  exporting.value = false;
                });
              }
            }).catch(() => {
              exporting.value = false;
            });
          });
        };

        
        return {
            table,
            handleSizeChange,
            handleCurrentChange,
            searchParams,
            searchUsers,
            formSize,
            userLocked,
            getLockedDict,
            handleDelete,
            handleEditClick,
            handleAddUser,
            userFormVisible,
            userForm,
            roles,
            loading,
            formRules,
            userEditFormVisible,
            handleEditUser,
            editFormRules,
            validate,
            handleExport,
            exporting,
            };
        }
}
const validate = ref(null);
